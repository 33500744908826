<template>
  <div class="p-5" v-if="params != false">
    <b-card>
      <b-row>
        <b-col  offset-md="4" md="2">
          <b-form-group label="Date de debut">
            <b-form-input type="date" v-model="filters['start']"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="2">
          <b-form-group label="Date de fin">
            <b-form-input type="date" v-model="filters['end']"></b-form-input>
          </b-form-group>
        </b-col>
        <b-col md="1" class="text-center mt-2">
          <b-button variant="primary" class="text-white mt-2" @click="init()"><i class="fas fa-search"></i></b-button>
        </b-col>
      </b-row>
      <b-row class="mt-3">
        <b-col offset-md="1" md="2" class="p-4 text-center text-dark rounded border border-secondary" style="background-color: #c9f7f5;">
          <p class="h5">Attestation active: <br>{{total_attestation}}</p>
        </b-col>
        <b-col md="2" class="p-4 text-center text-dark rounded border border-secondary ms-2" style="background-color: #c9f7f5;">
          <p class="h5">Clients Pautions: <br>{{total_client}}</p>
        </b-col>
        <b-col md="2" class="p-4 text-center text-dark rounded border border-secondary ms-2" style="background-color: #c9f7f5;">
          <p class="h5">Chiffre d'affaire: <br>{{total_ca}}€</p>
        </b-col>
        <b-col md="2" class="p-4 text-center text-dark rounded border border-secondary ms-2 bg-dange" style="background-color: #ff9d96;">
          <p class="h5">Remboursement: <br>{{total_remboursement}}€</p>
        </b-col>
        <b-col md="2" class="p-4 text-center text-dark rounded border border-secondary ms-2" style="background-color: #c9f7f5;">
          <p class="h5">Commission à payer: <br>{{total_com}}€</p>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <b-col md="7">
          <b-row>
            <h5>Général</h5>
            <b-col md="12">
              <b-button size="lg" variant="outline-success" @click="initChart(null)" :class="statistiqueSelect === null ? 'active' : ''">
                Prospects
              </b-button>
              <b-button size="lg" variant="outline-success" @click="initChart(1)" :class="statistiqueSelect === 1 ? 'active' : ''">
                Attestation active
              </b-button>
              <b-button size="lg" variant="outline-success" @click="initChart(5)" class="ms-3" :class="statistiqueSelect === 5 ? 'active' : ''">
                Clients Pautions
              </b-button>
              <b-button size="lg" variant="outline-success" @click="initChart(55)" class="ms-3" :class="statistiqueSelect === 55 ? 'active' : ''">
                Chiffre d'affaire
              </b-button>
              <b-button size="lg" variant="outline-success" @click="initChart(56)" class="ms-3" :class="statistiqueSelect === 56 ? 'active' : ''">
                Commission
              </b-button>
              <b-button size="lg" variant="outline-success" @click="initChart(57)" class="ms-3" :class="statistiqueSelect === 57 ? 'active' : ''">
                Remboursement
              </b-button>
            </b-col>
          </b-row>
          <apexcharts height="500" type="line" :options="chart.options" :series="chart.series"></apexcharts>
        </b-col>
        <b-col offset-md="1" md="4">
          <b-row>
            <h5>Répartition partenaire</h5>
            <b-col md="12">
              <b-button size="lg" variant="outline-success" :class="pieSelect === 'client' ? 'active' : ''" @click="pieSelect = 'client'">
                Clients
              </b-button>
              <b-button size="lg" variant="outline-success" class="ms-3" :class="pieSelect === 'ca' ? 'active' : ''" @click="pieSelect = 'ca'">
                C.A + Remb
              </b-button>
            </b-col>
          </b-row>
          <b-row md="12">
            <apexcharts type="donut" :options="sourcePieLabels" :series="pieSerie"></apexcharts>
          </b-row>
        </b-col>
      </b-row>
      <b-row class="mt-5">
        <h5>Tableau récapitulatif</h5>
        <b-table-simple stacked="md" striped hover>
          <b-thead>
            <b-tr>
              <b-th>Période (mensuelle)</b-th>
              <b-th>Nombre souscription</b-th>
              <b-th>Chiffre d'affaire</b-th>
              <b-th>Remboursement</b-th>
              <b-th>Commission a payer</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr v-for="(l, i) in list" :key="i">
              <b-td>Du {{dateFr(l._id.debut)}} au {{dateFr(l._id.fin)}}</b-td>
              <b-td>{{l.total_clients}}</b-td>
              <b-td>{{$store.api.price(l.ca_ttc)}}€</b-td>
              <b-td>{{$store.api.price(l.remboursement)}}€</b-td>
              <b-td>{{$store.api.price(l.com_ht)}}€</b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
      </b-row>
    </b-card>
  </div>
</template>
<script>
import VueApexCharts from 'vue-apexcharts'
export default {
  name: "AdminStatistique",
  components: {
    apexcharts: VueApexCharts,
  },
  data(){
    return {
      params:false,
      date_start:null,
      date_end:null,
      statistique:[],
      statistiqueSelect:null,
      pieSelect:"client",
      filters:{
        "start": null,
        "end":null,
        "status_id":4
      },
      chart:{
        options: {
          chart: {
            id: 'vuechart-example'
          },
          xaxis: {
            categories: []
          }
        },
        series: [{
          name: 'series-1',
          data: []
        }]
      },
      list:[],
      pies:{
        label:[],
        series:{
          ca:[],
          client:[]
        }
      }
    }
  },
  computed:{
    statsS(){
      var check = this.statistique.find(s => s.status_id === this.statistiqueSelect);
      if(check === undefined){
        return {
          "label":[],
          "data":[],
          "titre":""
        };
      }else{
        return check;
      }
    },
    total_attestation(){
      var check = this.statistique.find(s => s.status_id === 1);
      if(check === undefined){
        return 0;
      }
      return check.data.reduce((partialSum, a) => partialSum + a, 0);
    },
    total_client(){
      var check = this.statistique.find(s => s.status_id === 5);
      if(check === undefined){
        return 0;
      }
      return check.data.reduce((partialSum, a) => partialSum + a, 0);
    },
    total_ca(){
      var check = this.statistique.find(s => s.status_id === 55);
      if(check === undefined){
        return 0;
      }
      var total = 0;
      check.data.forEach((item) => {
        total += item
      });
      return total.toFixed(2);
    },
    total_com(){
      var check = this.statistique.find(s => s.status_id === 56);
      if(check === undefined){
        return 0;
      }
      var total = 0;
      check.data.forEach((item) => {
        total += item
      });
      return total.toFixed(2);
    },
    total_remboursement(){
      var check = this.statistique.find(s => s.status_id === 57);
      if(check === undefined){
        return 0;
      }
      var total = 0;
      check.data.forEach((item) => {
        total += item
      });
      return total.toFixed(2);
    },
    sourcePieLabels(){
      var chartOptions = {
        labels: this.pies.label,
        chart: {
          type: 'donut',
        },
        tooltip: {
          custom: function({series, seriesIndex, dataPointIndex, w}) {
            var mt = dataPointIndex != null ? series[seriesIndex][dataPointIndex] : series[seriesIndex];
            mt = Number(mt) === mt && mt % 1 !== 0 ? mt.toFixed(2) : mt;
            return `<div class="arrow_box"><span>${w.config.labels[seriesIndex]}: ${mt}</span></div>`;
          }
        }
      };
      return chartOptions;
    },
    pieSerie(){
      if(this.pieSelect === 'client'){
        return this.pies.series.client
      }else{
        return this.pies.series.ca
      }
    }
  },
  methods: {
    formatPrice(prix){
      const euro = new Intl.NumberFormat('fr-FR', {
        style: 'currency',
        currency: 'EUR',
        minimumFractionDigits: 2
      });
      return euro.format(prix)
    },
    dateFr(date){
      return date.split("-").reverse().join("/")
    },
    formatDate(date){
      var d = date.getDate();
      var m = date.getMonth() + 1;
      var y = date.getFullYear();
      m = m < 10 ? "0"+m : m;
      d = d < 10 ? "0"+d : d;
      return `${y}-${m}-${d}`;
    },
    init(){
      this.$store.api.ajax('/statistique/graphique', this.filters, res => {
        if(res.status){
          this.statistique = res.data.graph;
          this.initChart(this.statistiqueSelect);
          this.pies.label = [];
          this.pies.series.ca = [];
          this.pies.series.client = [];
          res.data.cam.forEach((item) => {
            this.pies.label.push(item.raison_social)
            this.pies.series.ca.push(item.ca)
            this.pies.series.client.push(item.clients)
          });
        }
      })
    },
    initChart(status){
      this.statistiqueSelect = status;
      this.chart = {
        ...this.chart,
        ...{
          options:{
            xaxis: {
              categories: this.statsS.label
            },
          },
          series:[{
            name: this.statsS.titre,
            data: this.statsS.data
          }]
        },
      };
    }
  },
  beforeMount() {
    var date = new Date();
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    this.filters['start'] = this.formatDate(firstDay);
    this.filters['end'] = this.formatDate(lastDay);
    this.$store.api.ajax('/commission/params', null, res => {
      if(res.status){
        res.data.Apporteur.forEach((item) => {
          item.label = item.raison_social
        });
        res.data.Partenaire.forEach((item) => {
          item.label = item.raison_social
        });
        this.params = res.data
      }
    })
  },
  mounted() {
    this.init();
    this.$store.api.ajax('/statistique/list', null, res => {
      if(res.status === true){
        this.list = res.data;
      }
    })
  }
}
</script>
<style lang="scss" scoped>
</style>
